const globalModule = {
  namespaced: true,
  state: {
    errCount: 0,
    typeFlag: [false, false, false, false]
  },
  getters: {},
  mutations: {
    addErrCount(state) {
      state.errCount++
    },
    clearErrCount(state) {
      state.errCount = 0
    },
    setTypeFlag(state, value) {
      state.typeFlag[value[0]] = value[1]
    }
  },
  actions: {}
}

export default globalModule
