import { getPageListData } from '@/service/main/exam/score'
const scoreModule = {
  namespaced: true,
  state: {
    scoreList: [],
    scoreCount: 0
  },
  getters: {
    pageListData(state, getters) {
      return (pageName) => {
        switch (pageName) {
          case 'score':
            return state.scoreList
        }
      }
    },
    pageListDataCount(state, getters) {
      return (pageName) => {
        switch (pageName) {
          case 'score':
            return state.scoreCount
        }
      }
    }
  },
  mutations: {
    changeScoreList(state, payload) {
      state.scoreList = payload
    },
    changeScoreCount(state, payload) {
      state.scoreCount = payload
    }
  },
  actions: {
    async getPageListAction({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const pageResult = await getPageListData(payload)
        commit('changeScoreList', pageResult.data)
        commit('changeScoreCount', pageResult.count)

        resolve(pageResult)
      })
    }
  }
}

export default scoreModule
