/**
 * 菜单根据order排序
 * @param {array} userMenus
 */
export function menuOrderSort(userMenus) {
  if (Array.isArray(userMenus)) {
    for (let i = 0; i < userMenus.length; i++) {
      for (let j = 0; j < userMenus.length; j++) {
        if (userMenus[i].meta.order < userMenus[j].meta.order) {
          const temp = userMenus[i]
          userMenus[i] = userMenus[j]
          userMenus[j] = temp
        }
      }
    }
    return userMenus
  } else {
    return []
  }
}
