import { yxRequest } from '@/service'

/**
 * 查询所有角色
 * @returns 所有角色列表
 */
export function queryRoleAll() {
  return yxRequest.get({
    url: '/sys/role/all'
  })
}
