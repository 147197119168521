const device = () => import('@/view/main/system/device/Device.vue')

export default {
  name: 'device',
  path: '/main/device/register',
  meta: {
    title: '无人机考评系统'
  },
  component: device,
  children: []
}
