import { yxRequest } from '@/service'
import { throttle } from '@/utils/throttle'
/**
 * 查询考生信息
 * @param {string} pageUrl
 * @param {object} queryInfo
 * @returns 考生信息列表
 */
export function getPageListData(pageUrl, queryInfo) {
  return yxRequest.get({
    url: pageUrl,
    params: {
      name: queryInfo.name,
      identity: queryInfo.identity,
      // status: queryInfo.status,
      status: 0,
      startTime: Array.isArray(queryInfo.date)
        ? queryInfo.date[0]
        : queryInfo.startTime,
      endTime: Array.isArray(queryInfo.date)
        ? queryInfo.date[1]
        : queryInfo.endTime,
      page: queryInfo.page,
      limit: queryInfo.limit
    },
    interceptors: {
      requestInterceptor: (config) => {
        if (!config.params.startTime) {
          throttle('warning', '未填写开始日期')
        }
        // if (config.params.status === '') {
        //   throttle('warning', '未选择考试状态')
        // }
        return config
      },
      responseInterceptor: (res) => {
        return res
      }
    }
  })
}

/**
 * 查询考生
 * @param {object} search_data
 */
export function getExamPageList(search_data) {
  return yxRequest.get({
    url: '/exam/examinee',
    params: search_data
  })
}

/**
 * 导出考生信息
 * @param {Array} ids
 * @returns 信息文件
 */
export function exportPageData(config) {
  return yxRequest.request({
    url: '/exam/examinee/export',
    method: 'POST',
    ...config
  })
}

/**
 * 新增考生
 * @param {Object} exam_data
 */
export function createExaminee(exam_data) {
  return yxRequest.post({
    url: '/exam/examinee',
    data: exam_data
  })
}

/**
 * 修改考生
 * @param {number} id 考生id
 * @param {object} exam_data 考生数据
 * @returns
 */
export function updateExaminee(id, exam_data) {
  return yxRequest.put({
    url: '/exam/examinee',
    data: {
      id,
      ...exam_data
    }
  })
}

/**
 * 删除考生
 * @param {number} id 考生id
 */
export function deleteExaminee(id) {
  return yxRequest.delete({
    url: `/exam/examinee/${id}`
  })
}

/**
 * 批量删除考生
 * @param {number[]} ids 考生id集合
 */
export function batchDeleteExaminee(exam_ids) {
  return yxRequest.delete({
    url: `/exam/examinee?ids=${exam_ids.join(',')}`,  // delete请求将参数作为url的一部分，解决ids被axios序列化的问题
    headers: {
      'Content-Type': 'application/json'
    },
  })
}
// [43, 44, 46, 48] 原始形式
// ids%5B0%5D=43&ids%5B1%5D=44&ids%5B2%5D=46&ids%5B3%5D=48 序列化
