import { yxRequest } from '@/service'

/**
 * 获取用户列表
 * @returns 用户列表
 */
export function getPageListData(queryInfo) {
  return yxRequest.get({
    url: '/sys/user',
    params: {
      ...queryInfo
    }
  })
}

/**
 * 新增用户
 * @param {object} addInfo
 * @returns 新增结果
 */
export function addUserData(addInfo) {
  return yxRequest.post({
    url: '/sys/user',
    data: addInfo
  })
}

/**
 * 更新用户信息
 * @param {object} updateInfo
 * @returns 更新结果
 */
export function updateUserData(updateInfo) {
  return yxRequest.put({
    url: '/sys/user',
    data: updateInfo
  })
}

/**
 * 删除指定用户
 * @param {number} id
 * @returns 删除结果
 */
export function deleteUserData(id) {
  return yxRequest.delete({
    url: '/sys/user/' + id
  })
}
