import {
  getPageListData,
  addOrganizeData
} from '@/service/main/system/organize/organize'
const organizeModule = {
  namespaced: true,
  state: {
    organizeList: [],
    organizeCount: 0
  },
  getters: {
    pageListData(state, getters) {
      return (pageName) => {
        return state.organizeList
      }
    },
    pageListDataCount(state, getters) {
      return (pageName) => {
        return state.organizeCount
      }
    }
  },
  mutations: {
    changeOrganizeList(state, payload) {
      state.organizeList = payload
    },
    changeOrganizeCount(state, payload) {
      state.organizeCount = payload
    }
  },
  actions: {
    getPageListAction({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const pageResult = await getPageListData(payload)
        commit('changeOrganizeList', pageResult.data)
        commit('changeOrganizeCount', pageResult.count)

        resolve(pageResult)
      })
    },
    async addOrganizeDataAction({ commit }, payload) {
      await addOrganizeData(payload)
    }
  }
}

export default organizeModule
