import { getPageListData } from '@/service/main/system/user/user'
const userModule = {
  namespaced: true,
  state: {
    userList: [],
    userCount: 0
  },
  getters: {
    pageListData(state, getters) {
      return (pageName) => {
        switch (pageName) {
          case 'user':
            return state.userList
        }
      }
    },
    pageListDataCount(state, getters) {
      return (pageName) => {
        switch (pageName) {
          case 'user':
            return state.userCount
        }
      }
    }
  },
  mutations: {
    changeUserList(state, payload) {
      state.userList = payload
    },
    changeUserCount(state, payload) {
      state.userCount = payload
    }
  },
  actions: {
    async getPageListAction({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const pageResult = await getPageListData(payload.queryInfo)
        commit('changeUserList', pageResult.data)
        commit('changeUserCount', pageResult.count)

        resolve(pageResult)
      })
    }
  }
}

export default userModule
