const anemometer = () => import('@/view/main/system/anemometer/Anemometer.vue')

export default {
  name: 'anemometer',
  path: '/main/device/anemometer',
  meta: {
    title: '无人机考评系统'
  },
  component: anemometer,
  children: []
}
