import { getPageListData } from '@/service/main/exam/exam'
const examModule = {
  namespaced: true,
  state: {
    examineeList: [],
    examineeCount: 0
  },
  getters: {
    pageListData(state, getters) {
      return (pageName) => {
        switch (pageName) {
          case 'examinee':
            return state.examineeList
        }
      }
    },
    pageListDataCount(state, getters) {
      return (pageName) => {
        switch (pageName) {
          case 'examinee':
            return state.examineeCount
        }
      }
    }
  },
  mutations: {
    changeExamineeList(state, payload) {
      state.examineeList = payload
    },
    changeExamineeCount(state, payload) {
      state.examineeCount = payload
    }
  },
  actions: {
    getPageListAction ({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const pageName = payload.pageName
        let pageUrl = ''
        switch (pageName) {
          case 'examinee':
            pageUrl = '/exam/examinee'
            break
        }
        const pageListResult = await getPageListData(pageUrl, payload.queryInfo)
        const firstPageWordUpper =
          pageName.slice(0, 1).toUpperCase() + pageName.slice(1).toLowerCase()
        commit(`change${firstPageWordUpper}List`, pageListResult.data)
        commit(`change${firstPageWordUpper}Count`, pageListResult.count)

        resolve(pageListResult)
      })
    }
  }
}

export default examModule
