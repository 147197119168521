const log = () => import('@/view/main/system/log/Log.vue')

export default {
  name: 'log',
  path: '/main/log/operator',
  meta: {
    title: '无人机考评系统'
  },
  component: log,
  children: []
}
