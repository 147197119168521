import { queryOrganizeAll } from '@/service/main/system/organize/organize'
import { queryRoleAll } from '@/service/main/system/role/role'
import {
  queryOperatorAll,
  queryBusinessAll
} from '@/service/main/system/log/log'
import localCache from '@/utils/LocalCache'
const system = {
  namespaced: true,
  state: {
    organizeAll: [],
    roleAll: [],
    operatorAll: [],
    businessAll: []
  },
  getters: {
    organizeAllData(state, getters) {
      return state.organizeAll
    },
    roleAllData(state, getters) {
      return state.roleAll
    },
    operatorAllData(state, getters) {
      return state.operatorAll
    },
    businessAllData(state, getters) {
      return state.businessAll
    }
  },
  mutations: {
    changeOrganizeAll(state, payload) {
      state.organizeAll = payload
    },
    changeRoleAll(state, payload) {
      state.roleAll = payload
    },
    changeOperatorAll(state, payload) {
      state.operatorAll = payload
    },
    changeBusinessAll(state, payload) {
      state.businessAll = payload
    }
  },
  actions: {
    async organizeAllAction({ commit }) {
      const organizeAllResult = await queryOrganizeAll()
      commit('changeOrganizeAll', organizeAllResult.data)
      localCache.setCache('organizeAll', organizeAllResult.data)
    },
    async roleAllAction({ commit }) {
      const roleAllResult = await queryRoleAll()
      commit('changeRoleAll', roleAllResult.data)
      localCache.setCache('roleAll', roleAllResult.data)
    },
    async operatorAllAction({ commit }) {
      const operatorAllResult = await queryOperatorAll()
      commit('changeOperatorAll', operatorAllResult.data)
      localCache.setCache('operatorAll', operatorAllResult.data)
    },
    async businessAllAction({ commit }) {
      const businessAllResult = await queryBusinessAll()
      commit('changeBusinessAll', businessAllResult.data)
      localCache.setCache('businessAll', businessAllResult.data)
    },
    // 存储组织和角色的列表及类型列表
    setupSystemAction({ commit }, payload) {
      commit('changeOrganizeAll', payload.organize)
      commit('changeRoleAll', payload.role)
      commit('changeOperatorAll', payload.operator)
      commit('changeBusinessAll', payload.business)
    }
  }
}

export default system
