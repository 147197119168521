import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  const _component_el_config_provider = _resolveComponent("el-config-provider");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_config_provider, {
    locale: $setup.zhCn
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  }, 8, ["locale"])]);
}