import { ElConfigProvider } from 'element-plus';
import 'element-plus/es/components/config-provider/style/css'

import zhCn from 'element-plus/lib/locale/lang/zh-cn';
export default {
  name: 'App',
  components: {
    ElConfigProvider
  },

  setup() {
    return {
      zhCn
    };
  }

};