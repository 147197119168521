import store from '@/store/index';
/**
 * @describe 节流函数，一段时间内只进行一次提示
 * @param {'success'|'info'|'warning'|'error'} type
 * @param {string} msg
 */

function throttle(type, msg) {
  switch (type) {
    case 'success':
      {
        if (store.state.global.typeFlag[0]) {
          return;
        } else {
          store.commit('global/setTypeFlag', [0, true]);
          ElMessage({
            type: type,
            message: msg
          });
          setTimeout(() => {
            store.commit('global/setTypeFlag', [0, false]);
          }, 3000);
        }

        break;
      }

    case 'info':
      {
        if (store.state.global.typeFlag[1]) {
          return;
        } else {
          store.commit('global/setTypeFlag', [1, true]);
          ElMessage({
            type: type,
            message: msg
          });
          setTimeout(() => {
            store.commit('global/setTypeFlag', [1, false]);
          }, 3000);
        }

        break;
      }

    case 'warning':
      {
        if (store.state.global.typeFlag[2]) {
          return;
        } else {
          store.commit('global/setTypeFlag', [2, true]);
          ElMessage({
            type: type,
            message: msg
          });
          setTimeout(() => {
            store.commit('global/setTypeFlag', [2, false]);
          }, 3000);
        }

        break;
      }

    case 'error':
      {
        if (store.state.global.typeFlag[3]) {
          return;
        } else {
          store.commit('global/setTypeFlag', [3, true]);
          ElMessage({
            type: type,
            message: msg
          });
          setTimeout(() => {
            store.commit('global/setTypeFlag', [3, false]);
          }, 3000);
        }

        break;
      }
  }
}

export { throttle };