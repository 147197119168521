import { yxRequest } from '../index'
import localCache from '@/utils/LocalCache'
import { throttle } from '@/utils/throttle'
/**
 * 用户登陆
 * @param {object} account
 * @returns promise
 */
export function requestAccountLogin (account) {
  return yxRequest.post({
    url: '/sys/user/login',
    data: {
      userName: account.userName,
      password: account.password
    },
    interceptors: {
      requestInterceptor: (config) => {
        return config
      },
      responseInterceptor: (res) => {
        const code = res.code
        if (code === 200) {
          throttle('success', '登陆成功')
          const keepPwd = localCache.getCache('keepPwd')
          if (keepPwd) {
            // 存储密码到localstorage
            localCache.setCache('username', account.userName)
            localCache.setCache('password', account.password)
          }
        }
        return res
      }
    }
  })
}

/**
 * 获取用户信息
 * @param {number} id
 * @returns
 */
export function requestUserInfoById (id) {
  return yxRequest.get({
    url: '/users/' + id
  })
}

/**
 * 获取用户角色菜单树
 * @param {number} roleId
 * @returns
 */
export function requestUserMenus () {
  return yxRequest.get({
    url: '/sys/menu'
  })
}

export function requestUpdatePassword (pwd) {
  return yxRequest.put({
    url: '/sys/user/password',
    data: {
      oldPassword: pwd.oldPassword,
      newPassword: pwd.newPassword
    }
  })
}

/**
 * 账号退出登陆
 * @returns
 */
export function reuqestSignOut () {
  return yxRequest.post({
    url: '/sys/user/signOut',
    interceptors: {
      requestInterceptor: (config) => {
        return config
      },
      responseInterceptor: (res) => {
        const code = res.code
        if (code === 200) {
          throttle('success', '退出登录成功')
        } else {
          throttle('error', '退出登录失败')
        }
        return res
      }
    }
  })
}
