import { getPageListData } from '@/service/main/system/log/log'
const logModule = {
  namespaced: true,
  state: {
    logList: [],
    logCount: 0
  },
  getters: {
    pageListData(state, getters) {
      return (pageName) => {
        return state.logList
      }
    },
    pageListDataCount(state, getters) {
      return (pageName) => {
        return state.logCount
      }
    }
  },
  mutations: {
    changeLogList(state, payload) {
      state.logList = payload
    },
    changeLogCount(state, payload) {
      state.logCount = payload
    }
  },
  actions: {
    getPageListAction({ commit }, payload) {
      return new Promise(async (resolve, reject) => {
        const pageResult = await getPageListData(payload)
        commit('changeLogList', pageResult.data)
        commit('changeLogCount', pageResult.count)

        resolve(pageResult)
      })
    }
  }
}

export default logModule
