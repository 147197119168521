import { yxRequest } from '@/service'
import { throttle } from '@/utils/throttle'

/**
 * 查询日志
 * @param {object} query 查询参数
 * @returns 日志列表
 */
export function getPageListData(query) {
  return yxRequest.get({
    url: '/sys/log/operator',
    params: {
      businessTypeId: query.queryInfo.businessTypeId,
      operatorTypeId: query.queryInfo.operatorTypeId,
      status: query.queryInfo.status,
      startTime: Array.isArray(query.queryInfo.date)
        ? query.queryInfo.date[0]
        : query.queryInfo.startTime,
      endTime: Array.isArray(query.queryInfo.date)
        ? query.queryInfo.date[1]
        : query.queryInfo.endTime,
      limit: query.queryInfo.limit,
      page: query.queryInfo.page
    },
    interceptors: {
      requestInterceptor: (config) => {
        return config
      },
      responseInterceptor: (res) => {
        if (res.msg === '状态不能为空') {
          throttle('warning', '状态不能为空')
        } else if (res.msg === '开始时间不能为空') {
          throttle('warning', '开始时间不能为空')
        } else if (res.msg === '业务id不能为空') {
          throttle('warning', '业务选项不能为空')
        } else if (res.msg === '操作id不能为空') {
          throttle('warning', '操作选项不能为空')
        }
        return res
      }
    }
  })
}

/**
 * 查询操作类型
 * @returns 所有操作类型
 */
export function queryOperatorAll() {
  return yxRequest.get({
    url: '/sys/log/operator/operator'
  })
}

/**
 * 查询业务类型
 * @returns 所以业务类型
 */
export function queryBusinessAll() {
  return yxRequest.get({
    url: '/sys/log/operator/business'
  })
}
