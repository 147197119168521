import {
  requestAccountLogin,
  reuqestSignOut,
  requestUserMenus
} from '@/service/login/login'
import localCache from '@/utils/LocalCache'
import router from '@/router/index'
import store from '@/store/index'
import { mapMenuToRoutes } from '@/utils/MapMenus'
import { menuOrderSort } from '@/utils/Menu'

const loginModule = {
  namespaced: true,
  state: {
    userInfo: {},
    userMenus: []
  },
  getters: {},
  mutations: {
    changeToken (state, payload) {
      state.token = payload
    },
    changeUserInfo (state, payload) {
      state.userInfo = payload
    },
    // TODO:changeUserMenus生产环境报错，还不知道原因
    changeUserMenus (state, payload) {
      state.userMenus = payload

      // 权限路由：动态添加路由
      const routes = mapMenuToRoutes(state.userMenus)
      if (Array.isArray(routes)) {
        routes.forEach((route) => {
          router.addRoute('main', route)
        })
      }
    }
  },
  actions: {
    async accountLoginAction ({ commit }, payload) {
      // 1、账号登陆
      const loginResult = await requestAccountLogin(payload)
      // 成功登陆才获取用户信息
      if (loginResult.code === 200) {
        // 2、获取用户信息
        const userInfo = loginResult.data
        localCache.setCache('userInfo', userInfo)
        commit('changeUserInfo', userInfo)

        // 3、获取用户菜单树
        const userMenusResult = await requestUserMenus()
        const userMenus = userMenusResult.data

        // TODO:排序导致生产环境编码过后报错
        const userMenusSort = menuOrderSort(userMenus)

        localCache.setCache('userMenus', userMenusSort)
        commit('changeUserMenus', userMenusSort)

        // 4、获取数据
        store.dispatch('system/organizeAllAction')
        store.dispatch('system/operatorAllAction')
        store.dispatch('system/businessAllAction')
        // 5、跳转首页
        router.push('/main/sys/info')
      }
    },

    // 退出登陆
    async accountSignOutAction ({ commit }) {
      await reuqestSignOut() // 发送退出请求
      store.dispatch('login/clearLoginState')
    },

    // 从缓存获取用户登陆信息及系统参数加载到vuex
    setupLoginState ({ commit }) {
      const userInfo = localCache.getCache('userInfo')
      const userMenus = localCache.getCache('userMenus')
      const operatorAll = localCache.getCache('operatorAll')
      const businessAll = localCache.getCache('businessAll')
      const organizeAll = localCache.getCache('organizeAll')

      if (userInfo) {
        commit('changeUserInfo', userInfo)
      }
      if (userMenus) {
        commit('changeUserMenus', userMenus)
      }
      // TODO:当用户角色没有操作组织和角色模块的时候，该部分会做localstorge存储undefined会导致登陆页报错
      if (businessAll) {
        store.dispatch('system/setupSystemAction', {
          organize: organizeAll,
          operator: operatorAll,
          business: businessAll
        })
      }
    },

    // 清除登陆缓存，并跳转到登陆页
    clearLoginState () {
      const keepPwd = localCache.getCache('keepPwd')
      if (!keepPwd) {
        localCache.removeCache('username')
        localCache.removeCache('password')
      }
      localCache.removeCache('userInfo')
      localCache.removeCache('userMenus')
      localCache.removeCache('operatorAll')
      localCache.removeCache('businessAll')
      localCache.removeCache('organizeAll')

      router.push('/login')
    }
  }
}

export default loginModule
