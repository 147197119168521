import { yxRequest } from '@/service/index'

/**
 * 查询考试成绩
 * @param {object} queryInfo 查询参数
 * @returns
 */
export function getPageListData(query) {
  return yxRequest.get({
    url: '/exam/score',
    params: {
      name: query.queryInfo.name,
      status: query.queryInfo.status,
      startTime: Array.isArray(query.queryInfo.date)
        ? query.queryInfo.date[0]
        : query.queryInfo.startTime,
      endTime: Array.isArray(query.queryInfo.date)
        ? query.queryInfo.date[1]
        : query.queryInfo.endTime,
      page: query.queryInfo.page,
      limit: query.queryInfo.limit
    }
  })
}

/**
 * (登陆才可用)查询飞行纪录详情
 * @param {number} uuid 唯一编号
 * @returns
 */
export function requestScoreDetail(uuid) {
  return yxRequest.get({
    url: '/exam/score/details',
    params: {
      uuid
    }
  })
}

/**
 * (登陆才可用)下载飞行纪录详情
 * @param {number} uuid
 * @returns
 */
export function requestScoreImport(uuid) {
  return yxRequest.get({
    url: '/exam/score/import/open',
    params: {
      uuid
    }
  })
}

/**
 * (公开无需登录)查询飞行纪录成绩详情
 * @param {number} uuid
 */
export function requestPublicScoreDetail(uuid) {
  return yxRequest.get({
    url: '/exam/score/details/open',
    params: {
      uuid
    }
  })
}

/**
 * (公开无需登录)下载飞行纪录详情配置
 * @param {number} uuid
 */
export function requestPublicScoreImport(uuid) {
  return yxRequest.get({
    url: '/exam/score/import/open',
    params: {
      uuid
    }
  })
}
