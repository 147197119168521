import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    meta: {
      title: '无人机考评系统'
    },
    component: () => import('@/view/login/Login.vue')
  },
  {
    name: 'main',
    path: '/main',
    meta: {
      title: '无人机考评系统'
    },
    component: () => import('@/view/main/Main.vue'),
    children: [
      {
        name: 'score-detail',
        path: '/main/exam/score/detail/:uuid',
        meta: { title: '无人机考评系统' },
        component: () => import('@/view/main/exam/score-detail/ScoreDetail.vue')
      }
    ]
  },
  {
    name: 'public-detail',
    path: '/main/exam/score/detail/:uuid',
    meta: { title: '无人机考评系统' },
    component: () => import('@/view/main/exam/score-detail/ScoreDetail.vue')
  },
  {
    name: '',
    path: '/:pathMatch(.*)*',
    meta: {
      title: '无人机考评系统'
    },
    redirect: '/login'
  },
  {
    name: 'download',
    path: '/download',
    meta: {
      title: '无人机考评系统下载'
    },
    component: () => import('@/view/download/Download.vue')
  }
]

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from) => {
  // 更换页面标题
  document.title = to.meta.title
})

export default router
