import YXRequest from './request/reuqest'
import { BASE_URL, TIME_OUT } from './request/config'
import qs from 'qs'
import store, { clearStore } from '@/store/index'
import { throttle } from '@/utils/throttle'
// import st

const yxRequest = new YXRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      // 拦截请求将POST请求数据转为FormData
      config.data = qs.stringify(config.data)
      return config
    },
    requestInterceptorCatch: (err) => {
      return err
    },
    responseInterceptor: (res) => {
      if (res.data.code !== 200 && res.data.code !== undefined) {
        throttle('error', res.data.msg)
        if (res.data.msg === '登录失效，请重新登录' || res.data.msg === '用户已在其他地方登录') {
          clearStore() // 退出登录清除登陆缓存
        }
      }

      return res
    },
    responseIntercptorCatch: (err) => {
      return err
    }
  }
})

export { yxRequest }
