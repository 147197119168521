import { createStore } from 'vuex'
import login from './login/login'
import examinee from './main/exam/exam'
import user from './main/user/user'
import system from './main/system/system'
import log from './main/system/log'
import organize from './main/system/organize'
import score from './main/exam/score'
import global from './global/index'
const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    examinee,
    user,
    system,
    log,
    organize,
    score,
    global
  }
})

// 加载store数据
export function setupStore () {
  // 加载用户数据
  store.dispatch('login/setupLoginState')
}
// 清除登陆数据
// TODO:(已解决)BUG 原密码错误会自动跳转执行clearStore
export function clearStore () {
  store.dispatch('login/clearLoginState')
}

export default store
