/**
 * 用户菜单树映射对应路由表
 * @param {array} userMenus 用户菜单树
 * @returns 用户权限对应的路由表
 */
export function mapMenuToRoutes(userMenus) {
  const routes = []
  const allRoutes = []

  // 1、默认加载全部路由
  const files = require.context('../router/main', true, /\.js$/)
  files.keys().forEach((key) => {
    const route = require('../router/main' + key.split('.')[1])
    allRoutes.push(route.default)
  })

  // 2、根据路由表注册对应路由 递归
  const _recurseGetRoute = (menus) => {
    for (const menu of menus) {
      if (!menu.children) {
        const route = allRoutes.find((route) => route.path === menu.path)
        if (route) {
          routes.push(route)
        }
      } else {
        _recurseGetRoute(menu.children)
      }
    }
  }

  _recurseGetRoute(userMenus)
  return routes
}

/**
 * 当前路由匹配菜单
 * @param {array} userMenus 路由菜单
 * @param {string} currentPath 当前页面路由
 * @param {object} parent 父节点
 * @returns 当前页面路由对应的order字符串
 */
export function pathMapToMenu (userMenus, currentPath, parent = null) {
  let parentNode = parent
  for (const item of userMenus) {
    if (item.children) {
      parentNode = item.meta
      const findMenu = pathMapToMenu(item.children, currentPath, parentNode) // 拿到递归到的匹配项返回
      if (findMenu) {
        return parentNode.order + '-' + findMenu.order
      }
    } else {
      if (item.path === currentPath) {
        return item // 返回找到的匹配项
      }
    }
  }
}
