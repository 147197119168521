import { yxRequest } from '@/service'
import { throttle } from '@/utils/throttle'
/**
 * 查询所有组织
 * @returns 组织列表
 */
export function queryOrganizeAll() {
  return yxRequest.get({
    url: '/sys/organize/all'
  })
}

/**
 * 查询组织
 * @param {object} query 查询参数
 * @returns 组织列表
 */
export function getPageListData(query) {
  return yxRequest.get({
    url: '/sys/organize',
    params: {
      organizeName: query.queryInfo.organizeName,
      organizeType: query.queryInfo.organizeType,
      limit: query.queryInfo.limit,
      page: query.queryInfo.page
    },
    interceptors: {
      requestInterceptor: (config) => {
        return config
      },
      responseInterceptor: (res) => {
        if (res.msg === '组织类型不能为空') {
          throttle('success', '组织类型不能为空')
        }
        return res
      }
    }
  })
}

/**
 * 查询组织类型
 * @returns 组织类型
 */
export function queryOrganizeType() {
  return yxRequest.get({
    url: '/sys/organize/type'
  })
}

/**
 * 添加组织
 * @param {object} addInfo
 * @returns
 */
export function addOrganizeData(addInfo) {
  return yxRequest.post({
    url: '/sys/organize',
    data: addInfo
  })
}

/**
 * 修改组织
 * @param {object} updateInfo
 * @returns
 */
export function updateOrganizeData(updateInfo) {
  return yxRequest.put({
    url: '/sys/organize',
    data: updateInfo
  })
}

/**
 * 删除组织
 * @param {number} id
 * @returns
 */
export function deleteOrganizeData(id) {
  return yxRequest.delete({
    url: `/sys/organize/${id}`
  })
}
